import Error from 'next/error'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { GetStaticProps } from 'next'

export default function NotFound() {
  return (
    <div className="p-4">
      <h1 className="text-center">Not found</h1>
      <Error statusCode={404} />
    </div>
  )
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  }
}
